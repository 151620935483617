
import {defineComponent, onMounted, ref, toRaw} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import store from "@/store";
import JwtService from "@/core/services/JwtService";
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router/router";
import EventCard from "@/components/events/EventCard.vue";


export default defineComponent({
  name: "search",
  components: {EventCard},
  data() {
    return {
      filterData: {},
      result:{},
    }
  },

  mounted() {
    if(this.$route.params && Object.keys(this.$route.params).length >0 ) {
     this.filterData =this.$route.params;
      this.filterData['search'] = this.$route.params.search
      if(this.$route.params.dateRange){
        this.filterData['dateRange'] = [new Date(this.$route.params.dateRange[0]),new Date(this.$route.params.dateRange[1])]
      }

      this.startSearch()
    }
  },
  methods: {
    startSearch() {
     store.dispatch(Actions.SEARCH,this.filterData).then(res=>{
          this.result =res.data
       console.log(this.result);
     });
    }
  }

});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "col-12 col-xxl-10 offset-xxl-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-4 mt-2" }
const _hoisted_7 = { class: "col-md-4 mt-2" }
const _hoisted_8 = { class: "col-md-4 mt-2" }
const _hoisted_9 = {
  key: 0,
  class: "row mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_EventCard = _resolveComponent("EventCard")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("search_results")), 1),
          _createVNode(_component_el_form, {
            id: "kt_modal_new_target_form",
            model: _ctx.filterData,
            class: "form"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_form_item, { class: "mb-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.filterData.search,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterData.search) = $event)),
                        placeholder: _ctx.$t('search'),
                        clearable: ""
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.filterData.dateRange,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterData.dateRange) = $event)),
                    class: "dateRangePicker",
                    type: "daterange",
                    "range-separator": "To",
                    "start-placeholder": "Start Date",
                    "end-placeholder": "End date"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        onClick: _ctx.startSearch,
                        type: "primary",
                        round: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("search")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ])
    ]),
    (Object.keys(_ctx.result).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12 col-lg-4 event-item",
              key: index
            }, [
              _createVNode(_component_EventCard, {
                data: item,
                index: index
              }, null, 8, ["data", "index"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (Object.keys(_ctx.result).length === 0)
      ? (_openBlock(), _createBlock(_component_el_empty, {
          key: 1,
          description: _ctx.$t('NOTHING_FOUND')
        }, null, 8, ["description"]))
      : _createCommentVNode("", true)
  ]))
}